import './App.css';

function App() {

	let projects = [
		{
			name: 'Mosaiclipse',
			subtitle: 'P5.js',
			website: 'https://editor.p5js.org/0xMoe/sketches/UUsfuNnNd',
			images: [
				'https://bafybeif6sjimzdi3sneezoy47xtas4vezgrl2snav7hgh4nvxvpp6pqc4i.ipfs.nftstorage.link/Mosaiclipse-1.png',
				'https://bafybeif6sjimzdi3sneezoy47xtas4vezgrl2snav7hgh4nvxvpp6pqc4i.ipfs.nftstorage.link/Mosaiclipse-2.png',
				'https://bafybeif6sjimzdi3sneezoy47xtas4vezgrl2snav7hgh4nvxvpp6pqc4i.ipfs.nftstorage.link/Mosaiclipse-3.png',
				'https://bafybeif6sjimzdi3sneezoy47xtas4vezgrl2snav7hgh4nvxvpp6pqc4i.ipfs.nftstorage.link/Mosaiclipse-4.png',
			]
		},
		{
			name: 'Brushes',
			subtitle: 'Brush strokes in P5.js',
			website: 'https://editor.p5js.org/0xMoe/sketches/gFC8oEJdx',
			images: [
				'https://bafybeiefadxlcrikg7lpff77dcvv3kg5przbad2jv3d6b7rhwdgah3zr5y.ipfs.nftstorage.link/p5js-1671542032516.png',
				'https://bafybeiefadxlcrikg7lpff77dcvv3kg5przbad2jv3d6b7rhwdgah3zr5y.ipfs.nftstorage.link/p5js-1671640684653.png',
				'https://bafybeiefadxlcrikg7lpff77dcvv3kg5przbad2jv3d6b7rhwdgah3zr5y.ipfs.nftstorage.link/p5js-1671542058321.png',
				'https://bafybeiefadxlcrikg7lpff77dcvv3kg5przbad2jv3d6b7rhwdgah3zr5y.ipfs.nftstorage.link/p5js-1671660166982.png',
			]
		},
		{
			name: 'Hex',
			subtitle: 'Hex grid in P5.js',
			website: 'https://editor.p5js.org/0xMoe/sketches/nPkE8emp7',
			images: [
				'https://bafybeicj3dqldldjspzqrfqnjegq24n7quue53tfl5ahxaoe7wskow3i5y.ipfs.nftstorage.link/p5js-1671303735207.png',
				'https://bafybeicj3dqldldjspzqrfqnjegq24n7quue53tfl5ahxaoe7wskow3i5y.ipfs.nftstorage.link/p5js-1671303908082.png',
				'https://bafybeicj3dqldldjspzqrfqnjegq24n7quue53tfl5ahxaoe7wskow3i5y.ipfs.nftstorage.link/p5js-1671303925347.png',
				'https://bafybeicj3dqldldjspzqrfqnjegq24n7quue53tfl5ahxaoe7wskow3i5y.ipfs.nftstorage.link/p5js-1671304250845.png',
			]
		},
		{
			name: 'Merge',
			subtitle: 'Perlin noise in P5.js',
			website: 'https://editor.p5js.org/0xMoe/sketches/j2TPwIOrQ',
			images: [
				'https://bafybeiga2nw6z77w5wfpzcicquj5sbanz4ivqbvzrwj3qjzvdmsei6zoza.ipfs.dweb.link/25.png',
				'https://bafybeiga2nw6z77w5wfpzcicquj5sbanz4ivqbvzrwj3qjzvdmsei6zoza.ipfs.dweb.link/0.png',
				'https://bafybeiga2nw6z77w5wfpzcicquj5sbanz4ivqbvzrwj3qjzvdmsei6zoza.ipfs.dweb.link/26.png',
				'https://bafybeiga2nw6z77w5wfpzcicquj5sbanz4ivqbvzrwj3qjzvdmsei6zoza.ipfs.dweb.link/12.png',
			]
		},
		{
			name: 'AVERRY',
			website: 'https://etherscan.io/token/0x10c43e18836fe9c229a37ee1e76936e4172f0a71#inventory',
			subtitle: 'Ethereum On-Chain SVG',
			images: [
				'https://openseauserdata.com/files/6d69088a04e5a9ba1b029e642e2a7e47.svg',
				'https://openseauserdata.com/files/9a8787a97b502293a74cd094c3c6465e.svg',
				'https://openseauserdata.com/files/7ad696497238d68e17965ed204d71d31.svg',
				'https://openseauserdata.com/files/f8abc4786ae24b0ec32587c3f55fe95f.svg',
			]
		},
		{
			name: 'Turbulence',
			subtitle: 'Perlin noise in P5.js',
			website: 'https://editor.p5js.org/0xMoe/sketches/mBYGEJPNs',
			images: [
				'https://cloudflare-ipfs.com/ipfs/bafybeia5awehqiaofvlfmrry33esgslx34r67kiojryyqghdypo62giu4m/190.png',
				'https://cloudflare-ipfs.com/ipfs/bafybeia5awehqiaofvlfmrry33esgslx34r67kiojryyqghdypo62giu4m/191.png',
				'https://cloudflare-ipfs.com/ipfs/bafybeia5awehqiaofvlfmrry33esgslx34r67kiojryyqghdypo62giu4m/193.png',
				'https://cloudflare-ipfs.com/ipfs/bafybeia5awehqiaofvlfmrry33esgslx34r67kiojryyqghdypo62giu4m/202.png',
			]
		},
		{
			name: 'ΞLLISS',
			website: 'https://etherscan.io/token/0xa94126551e457049c39b23f40b636299d55735dd#inventory',
			subtitle: 'Abstract geometry',
			images: [
				'https://ipfs.nftstorage.link/ipfs/bafybeigqzj2l4gbxdkf45unupru2ehatmhc3g36wc2xndyawmumxao6vo4/32.png',
				'https://ipfs.nftstorage.link/ipfs/bafybeigqzj2l4gbxdkf45unupru2ehatmhc3g36wc2xndyawmumxao6vo4/17.png',
				'https://ipfs.nftstorage.link/ipfs/bafybeigqzj2l4gbxdkf45unupru2ehatmhc3g36wc2xndyawmumxao6vo4/26.png',
				'https://ipfs.nftstorage.link/ipfs/bafybeigqzj2l4gbxdkf45unupru2ehatmhc3g36wc2xndyawmumxao6vo4/49.png',
			]
		},
		{
			name: 'Pixel Loot',
			website: 'https://opensea.io/collection/pixel--loot',
			subtitle: 'AI-generated pixel art for Loot',
			images: [
				'https://lh3.googleusercontent.com/5k_-rLExH7PMdMYgUfTkuFhw3QuRbwMiIT81oJ2Zo5eVDTJdTU6TV6zcYLFvJGGtmMfsDsIY3nK0AXvv7mnufGbctGid4u1-09g7T9I=w600',
				'https://lh3.googleusercontent.com/kx9ia2HXk3ZWpl_eCanAIy2ZaoFZeyVULwZGHLcd_4OYEQrpxSd9fEgJ1cDIFLFN60BgMjy3s98m0MjWvEQJjS6dhWBotvboZgix=w600',
				'https://lh3.googleusercontent.com/xqZeAgngtOT_4tjcQJYq1F20cfm9JvxNBG6TRshPrP3_YVxD0HVO7sScKrAOMjGOr2zYknPSH4fV0l4cwyDqjIBHwStgINbR3N-F=w600',
				'https://lh3.googleusercontent.com/bxLoRxKPNJ6kbptJ2MH11MxYqEewFYikxHrW5VWfAYpabUOe7EqynHw15vAGLDn-2JKJTYHZalMVM8Jqc6ps9sZjubD9mfB41MuLTw=w600',
			]
		},
		{
			name: 'Other Projects',
			website: 'https://0xMoe.com',
			images: [
			]
		},
	]

	return (
		<div className="App container text-center px-4">
			<div className="mt-4 mt-md-5 mb-5 py-5">
				<div className="h1 fw-bold">MOΞ</div>
				<div className="opacity-50">Art Experiments in Code</div>
			</div>

			{projects.map(p =>
				<div className="mb-5">
					<div className="h5 fw-bold mb-1">
						{p.name}
						<a href={p.website} target="_blank" className="text-reset opacity-75 ms-2"><i className="bi bi-box-arrow-up-right" style={{ fontSize: '0.6em', verticalAlign: '0.2em' }}></i></a>
					</div>
					<div className="small mb-3 opacity-50">{p.subtitle}</div>
					<div className="row">
						{p.images.map(image =>
							<div className="col-md-6 col-lg-3 mb-4">
								<img src={image} className="img-fluid shadow"></img>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default App;
